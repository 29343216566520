
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Jpg from "../assets/jpg";

// Component

import Header from "./user/defaulLayout/Header";
import Footer from "./user/defaulLayout/Footer";

export default {
      Svg,
      Png,
      Jpg,
      Header,
      Footer,
};
