import automated from './images/png/automated.png';
import client from './images/png/client.png';
import delivery from './images/png/delivery.png';
import equity from './images/png/equity.png';
import involvment from './images/png/involvment.png';
import multiple from './images/png/multiple.png';

import polymer from './images/png/polymer.png';
import steel from './images/png/steel.png';
import seller from './images/png/seller.png';
import buyer from './images/png/buyer.png';
import user from './images/png/user.png';

import polymerBlog from './images/png/polymerBlog.png';
import steelBlog from './images/png/steelBlog.png';

import polymerOriginal from './images/png/polymer-original.png';

const Png = {
      automated,
      client,
      delivery,
      equity,
      involvment,
      multiple,
      polymer,
      steel,
      user,
      seller,
      buyer,
      polymerBlog,
      steelBlog,
      polymerOriginal,
}

export default Png;