import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PagesIndex";

export default function Home() {
  return (
    <>
      {/* Hero section start */}
      <Index.Box className="home-hero-main" component="section">
        <Index.Box className="banner-content" container>
          <Index.Box className="left-wrape" container>
            <Index.Typography component="h1">
              Premier digital marketplace for steel & polymer products
            </Index.Typography>
            <Index.Box className="search-area">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder="Seamless Pipe"
              />
              <Index.Button type="submit" className="search-btn">
                Search
              </Index.Button>
            </Index.Box>
            <Index.Box className="tab-wrape">
              <Index.Typography component="p">
                Frequently searched:
              </Index.Typography>
              <Index.Link to="/coming-soon" className="">
                Seamless Pipe
              </Index.Link>
              <Index.Link to="/coming-soon" className="">
                Welded Pipe
              </Index.Link>
              <Index.Link to="/coming-soon" className="">
                API Pipe
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="right-wrape">
            <Index.Box className="link-wrape">
              <Index.Link to="/coming-soon" className="">
                Buyer Central
              </Index.Link>
              <Index.Link to="/coming-soon" className="">
                Become a supplier
              </Index.Link>
              <Index.Link to="/coming-soon" className="">
                Get the app
              </Index.Link>
            </Index.Box>
            <Index.Box className="controler">
              <Index.Box className="swiper-button-prev">
                <img src={PageIndex.Svg.prvblackarrrow} />
              </Index.Box>
              <Index.Box className="swiper-button-next">
                <img src={PageIndex.Svg.nxtblackarrrow} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <PageIndex.Swiper
          loop={true}
          modules={[PageIndex.Navigation, PageIndex.Pagination]}
          className="mySwiper"
          pagination={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide3} />
          </PageIndex.SwiperSlide>
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide1} />
          </PageIndex.SwiperSlide>
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide2} />
          </PageIndex.SwiperSlide>
        </PageIndex.Swiper>

        <Index.Box className="controler mobile-shows">
          <Index.Box className="swiper-button-prev">
            <img src={PageIndex.Svg.prvblackarrrow} />
          </Index.Box>
          <Index.Box className="swiper-button-next">
            <img src={PageIndex.Svg.nxtblackarrrow} />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* Hero section end */}

      {/* About Us section start */}
      <Index.Box className="about-us-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Grid item lg={12} className="grid inner-wrape">
            <Index.Box className="left-section">
              <Index.Typography component="h2">
              About Stelmart{" "}
                <img src={PageIndex.Svg.whitelongarrow} className="arrow" />
              </Index.Typography>
              <Index.Typography component="p">
                The Digital platform that revolutions Steel industry
              </Index.Typography>
            </Index.Box>
            <Index.Box className="right-section">
              <Index.Typography component="p">
                Our digital application is designed to make the purchasing and exploration of steel and polymer products easier and more convenient for individuals and businesses alike.
              </Index.Typography>
              <Index.Typography component="p">
                At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.
              </Index.Typography>
              <Index.Link className="link" to="/coming-soon">
                Learn more <img src={PageIndex.Svg.bluearrow} />
              </Index.Link>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      {/* About Us section end */}

      {/* Join Us section start */}
      <Index.Box className="join-us-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Grid item lg={12} className="grid inner-wrape">
            <Index.Box className="big-size">
              <img src={PageIndex.Jpg.joinus} />
              <Index.Box className="content">
                <Index.Typography component="h3">Join us </Index.Typography>
                <Index.Typography component="p">
                  Attention Buyers, Sellers Welcome to the future of steel and polymer procurement with STELMART. Join our cutting-edge platform where buying and selling of steel and polymer Products becomes easy and transparent.
                </Index.Typography>
                <Index.Typography component="p">
                  Connect, trade, and facilitate deals with ease. Register today – become part of the
STELMART community
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="small-size">
              <img src={PageIndex.Png.buyer} />
              <Index.Box className="content">
                <Index.Typography component="h3">Buyer </Index.Typography>
                <Index.Typography component="p">
                  Meet the diverse variety of steel and polymer products with high quality and support
                </Index.Typography>
                <Index.Link to="/coming-soon" className="link">
                  Sign up as Buyer <img src={PageIndex.Svg.frwdbluearrrow} />
                </Index.Link>
              </Index.Box>
            </Index.Box>
            <Index.Box className="small-size">
              <img src={PageIndex.Png.seller} />
              <Index.Box className="content">
                <Index.Typography component="h3">Seller</Index.Typography>
                <Index.Typography component="p">
                  Join us to grow & elevate your steel and polymer business with cutting-edge technology
                </Index.Typography>
                <Index.Link to="/coming-soon" className="link">
                  Sign up as Seller <img src={PageIndex.Svg.frwdbluearrrow} />
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      {/* Join Us section end */}

      {/* States section start */}
      <Index.Box className="states-wrape" component="section">
        <Index.Box className="container">
          <Index.Box className="inner">
            <Index.Box className="state">
              <Index.Typography component="h5">1475</Index.Typography>
              <Index.Typography component="p">Trusted Buyers</Index.Typography>
            </Index.Box>
            <Index.Box className="state two">
              <Index.Typography component="h5">
                2K<sub>+</sub>
              </Index.Typography>
              <Index.Typography component="p">Trusted Sellers</Index.Typography>
            </Index.Box>
            <Index.Box className="state three">
              <Index.Typography component="h5">
                60<sub>+</sub>
              </Index.Typography>
              <Index.Typography component="p">
                Enquiries Received
              </Index.Typography>
            </Index.Box>
            <Index.Box className="state">
              <Index.Typography component="h5">
                40<sub>+</sub>
              </Index.Typography>
              <Index.Typography component="p">
                Quotations Submitted
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* States section end */}

      {/* key feature section start */}
      <Index.Box className="key-feature-wrape" component="section">
        <Index.Typography component="h4">Our key features</Index.Typography>
        <Index.Typography component="p">
          Lorem ipsum dolor sit amet consectetur.
        </Index.Typography>
        <Index.Box className="crousel-wrape">
          <PageIndex.Swiper
            slidesPerView={3.4}
            spaceBetween={20}
            centeredSlides={true}
            loop={true}
            pagination={{
              el: ".my-custom-pagination-div",
              clickable: true,
              type: "fraction",
            }}
            modules={[PageIndex.Navigation, PageIndex.Pagination]}
            className="mySwiper"
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              280: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerView: 1.4,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerView: 1.4,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerView: 2.4,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerView: 2.4,
              },
              1260: {
                slidesPerView: 3,
                spaceBetween: 20,
                slidesPerView: 3.4,
              },
            }}
          >
            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluesearch} alt="" />
                </Index.Box>
                <Index.Tooltip title="Capabilities" placement="right-end">
                <Index.Typography component="h6">
                  Search and Filtering Capabilities
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="steel or PVC pipes." placement="right-end">
                <Index.Typography component="p">
                  The application empowers users with advanced filtering and
                  customization options, making it easy to search for and find
                  the right steel or PVC pipes.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluetick} />
                </Index.Box>
                <Index.Tooltip title="" placement="right-end">
                <Index.Typography component="h6">
                  Turnkey Solutions
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="This includes everything from product
                  selection to delivery." placement="right-end">
                <Index.Typography component="p">
                  Stelmart provides end-to-end services and support to meet the
                  diverse needs of buyers and sellers in both the steel and PVC
                  pipe industries. This includes everything from product
                  selection to delivery.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title="" placement="right-end">
                <Index.Typography component="h6">
                  Detailed Product Information
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="selecting the right pipes for their projects." placement="right-end">
                <Index.Typography component="p">
                  Stelmart's listings provide detailed product information for
                  steel pipes and PVC pipes, helping users make informed
                  decisions when selecting the right pipes for their projects.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title=" and Buyers" placement="right-end">
                <Index.Typography component="h6">
                 Verified Supplier/ Manufacturers and Buyers
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="to quality standards and offer
                  genuine products." placement="right-end">
                <Index.Typography component="p">
                  Stelmart collaborates with verified suppliers and
                  manufacturers, guaranteeing buyers access to reputable and
                  trustworthy sellers who adhere to quality standards and offer
                  genuine products.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluesearch} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Search and Filtering Capabilities
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="right steel or PVC pipes." placement="right-end">
                <Index.Typography component="p">
                  The application empowers users with advanced filtering and
                  customization options, making it easy to search for and find
                  the right steel or PVC pipes.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluetick} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Turnkey Solutions
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="This includes everything from product
                  selection to delivery." placement="right-end">
                <Index.Typography component="p">
                  Stelmart provides end-to-end services and support to meet the
                  diverse needs of buyers and sellers in both the steel and PVC
                  pipe industries. This includes everything from product
                  selection to delivery.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Detailed Product Information
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="selecting the right pipes for their projects.." placement="right-end">
                <Index.Typography component="p">
                  Stelmart's listings provide detailed product information for
                  steel pipes and PVC pipes, helping users make informed
                  decisions when selecting the right pipes for their projects.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title="to quality standards and offer
                  genuine products." placement="right-end">
                <Index.Typography component="h6">
                  Verified Supplier/ Manufacturers and Buyers
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="sellers who adhere to quality standards and offer
                  genuine products." placement="right-end">
                <Index.Typography component="p">
                  Stelmart collaborates with verified suppliers and
                  manufacturers, guaranteeing buyers access to reputable and
                  trustworthy sellers who adhere to quality standards and offer
                  genuine products.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluesearch} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Search and Filtering Capabilities
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title=" the right steel or PVC pipes." placement="right-end">
                <Index.Typography component="p">
                  The application empowers users with advanced filtering and
                  customization options, making it easy to search for and find
                  the right steel or PVC pipes.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluetick} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Turnkey Solutions
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="This includes everything from product
                  selection to delivery." placement="right-end">
                <Index.Typography component="p">
                  Stelmart provides end-to-end services and support to meet the
                  diverse needs of buyers and sellers in both the steel and PVC
                  pipe industries. This includes everything from product
                  selection to delivery.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title=" " placement="right-end">
                <Index.Typography component="h6">
                  Detailed Product Information
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="pipes for their projects." placement="right-end">
                <Index.Typography component="p">
                  Stelmart's listings provide detailed product information for
                  steel pipes and PVC pipes, helping users make informed
                  decisions when selecting the right pipes for their projects.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="white-box">
                <Index.Box className="icon-wrape">
                  <img src={PageIndex.Svg.bluelist} alt="" />
                </Index.Box>
                <Index.Tooltip title="and Buyers" placement="right-end">
                <Index.Typography component="h6">
                  Verified Supplier/ Manufacturers and Buyers
                </Index.Typography>
                </Index.Tooltip>
                <Index.Tooltip title="sellers who adhere to quality standards and offer
                  genuine products." placement="right-end">
                <Index.Typography component="p">
                  Stelmart collaborates with verified suppliers and
                  manufacturers, guaranteeing buyers access to reputable and
                  trustworthy sellers who adhere to quality standards and offer
                  genuine products.
                </Index.Typography>
                </Index.Tooltip>
              </Index.Box>
            </PageIndex.SwiperSlide>
          </PageIndex.Swiper>

          <Index.Box className="slide-controller">
            <Index.Box className="swiper-button-prev">
              <img src={PageIndex.Svg.prvwhitethik} alt="" />
            </Index.Box>
            <Index.Box className="swiper-button-next">
              <img src={PageIndex.Svg.nxtwhitethik} alt="" />
            </Index.Box>
            <div className="my-custom-pagination-div"></div>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* key feature section end */}

      {/* User interface section start */}
      <Index.Box className="user-interface-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Grid item lg={12} className="grid">
            <Index.Typography component="h4">
              STELMART USER <span>INTERFACE</span>
            </Index.Typography>
          </Index.Grid>
        </Index.Grid>

        <Index.Grid className="container" container>
          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.equity} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Enquiry and quote requests
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.multiple} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Multiple sellers and comparative sheet
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.client} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Client choice and negotiation
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.automated} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Automated communication
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.delivery} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Delivery updates
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Grid item lg="4" md="6" sm="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <img src={PageIndex.Png.involvment} alt="" />
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Involvements of Banks
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    Learn more{" "}
                    <img
                      src={PageIndex.Svg.bluearrow}
                      className="arrow"
                      alt=""
                    />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      {/* User interface section end */}

      {/* application work section start */}
      <Index.Box className="app-work-wrape" component="section">
        <Index.Box container className="container">
          <Index.Typography component="h4">
            How does the stelmart application work
          </Index.Typography>
          <Index.Box className="flex-section">
            <Index.Box className="up-wrape">
              <Index.Box className="sec">
                <img src={PageIndex.Svg.howto} alt="" />
                <Index.Typography component="h6">Registration</Index.Typography>
                <Index.Typography component="p">
                  Users download the app, register, and set up their profiles.
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec secound">
                <img src={PageIndex.Svg.exportnote} alt="" />
                <Index.Typography component="h6">Inquiries</Index.Typography>
                <Index.Typography component="p">
                  Buyers send product inquiries or quote requests to sellers.
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec third">
                <img src={PageIndex.Svg.partnerexchange} />
                <Index.Typography component="h6">Negotiation</Index.Typography>
                <Index.Typography component="p">
                  Buyers and sellers can negotiate terms if necessary
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec fourth">
                <img src={PageIndex.Svg.route} />
                <Index.Typography component="h6">
                  Order Tracking
                </Index.Typography>
                <Index.Typography component="p">
                  Real-time order tracking keeps buyers informed
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec">
                <img src={PageIndex.Svg.verifyblack} />
                <Index.Typography component="h6">
                  Order complete
                </Index.Typography>
                <Index.Typography component="p">
                  Order successfully delivered
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="middle-wrape">
              <img src={PageIndex.Svg.appwork} />
            </Index.Box>
            <Index.Box className="down-wrape">
              <Index.Box className="sec">
                <img src={PageIndex.Svg.mystery} />
                <Index.Typography component="h6">Browse</Index.Typography>
                <Index.Typography component="p">
                  Buyers explore the catalogue, while sellers list their products
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec secound">
                <img src={PageIndex.Svg.speaker} />
                <Index.Typography component="h6">Quotes</Index.Typography>
                <Index.Typography component="p">
                  Sellers respond with quotes, and buyers can compare multiple offers
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec third">
                <img src={PageIndex.Svg.game} />
                <Index.Typography component="h6">Payment & Banking Assistance</Index.Typography>
                <Index.Typography component="p">
                  Buyers securely complete payments within the app / using options offered by banks
                </Index.Typography>
              </Index.Box>

              <Index.Box className="sec fourth">
                <img src={PageIndex.Svg.delivery} />
                <Index.Typography component="h6">
                Delivery Updates
                </Index.Typography>
                <Index.Typography component="p">
                  ellers provide delivery status updates
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* application work section end */}

      {/* View Category section start */}
      <Index.Box className="view-our-category-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Grid item lg="12" className="grid">
            <Index.Typography component="h4">
              View our categories
              <span>
                STELMART primarily specializes in two product categories: Steel products and Polymer products.
              </span>
            </Index.Typography>
          </Index.Grid>
        </Index.Grid>

        <Index.Grid className="container" container>
          <Index.Grid item lg="6" sm="6" md="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <Index.Box className="img-wrape">
                <Index.Typography component="h6">Polymer</Index.Typography>
                <img src={PageIndex.Png.polymerOriginal} />
              </Index.Box>
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  A wide range of UPVC, CPVC, HDPE pipes and fittings...
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    <img src={PageIndex.Svg.roundarrow} className="arrow" />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
          <Index.Grid item lg="6" sm="6" md="6" xs="12" className="grid">
            <Index.Box className="box-grid">
              <Index.Box className="img-wrape">
                <Index.Typography component="h6">Steel</Index.Typography>
                <img src={PageIndex.Png.steel} />
              </Index.Box>
              <Index.Box className="bottom-info">
                <Index.Typography component="p" className="detail">
                  Seamless Steel Pipes, ERW pipes, LSAW pipes, HSAW pipes, Steel Sheets, coil...
                </Index.Typography>
                <Index.Typography component="p" className="link">
                  <Index.Link to="/coming-soon">
                    <img src={PageIndex.Svg.roundarrow} className="arrow" />{" "}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      {/* View Category section end */}

      {/* blog section start */}
      <Index.Box className="blog-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Box className="top-content">
            <Index.Box className="left-wrape">
              <Index.Typography component="h5">Blogs</Index.Typography>
              <Index.Typography component="p">
                Key Challenges Faced by Steel & Polymer product buyers in the Indian market
              </Index.Typography>
            </Index.Box>
            <Index.Box className="right-wrape">
              <Index.Box className="white-box">
                <Index.Typography component="h5">Quality Concerns </Index.Typography>
                <Index.Typography component="p">
                  Ensuring that the manufactured products are of good quality is a major concern for buyers. Sometimes, it's hard to know if the Products...
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="crousel-wrape">
            <PageIndex.Swiper
              slidesPerView={1}
              spaceBetween={20}
              centeredSlides={true}
              loop={true}
              pagination={{
                el: ".my-custom",
                clickable: true,
                type: "fraction",
              }}
              modules={[PageIndex.Navigation, PageIndex.Pagination]}
              className="mySwiper"
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
              <PageIndex.SwiperSlide>
                <Index.Box className="box-grid">
                  <Index.Box className="img-wrape">
                    <Index.Typography component="h6">Polymer</Index.Typography>
                    <img src={PageIndex.Png.polymer} />
                  </Index.Box>
                  <Index.Box className="bottom-info">
                    <Index.Typography component="p" className="detail">
                      Learn more{" "}
                    </Index.Typography>
                    <Index.Typography component="p" className="link">
                      <Index.Link to="/coming-soon">
                        <img
                          src={PageIndex.Svg.frwdorangearrrow}
                          className="arrow"
                        />{" "}
                      </Index.Link>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </PageIndex.SwiperSlide>

              <PageIndex.SwiperSlide>
                <Index.Box className="box-grid">
                  <Index.Box className="img-wrape">
                    <Index.Typography component="h6">Polymer</Index.Typography>
                    <img src={PageIndex.Png.polymer} />
                  </Index.Box>
                  <Index.Box className="bottom-info">
                    <Index.Typography component="p" className="detail">
                      Learn more{" "}
                    </Index.Typography>
                    <Index.Typography component="p" className="link">
                      <Index.Link to="/coming-soon">
                        <img src={PageIndex.Svg.frwdorangearrrow} className="arrow" />{" "}
                      </Index.Link>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </PageIndex.SwiperSlide>
            </PageIndex.Swiper>
          </Index.Box>
          <Index.Box className="slide-controller">
            <div className="my-custom"></div>
            <Index.Box className="control">
              <Index.Box className="swiper-button-prev">
                <img src={PageIndex.Svg.frwmtorangearrrow} />
              </Index.Box>
              <Index.Box className="swiper-button-next">
                <img src={PageIndex.Svg.backmtorangearrrow} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Grid>
      </Index.Box>
      {/* blog section end */}

      {/* testimonial section start */}
      <Index.Box className="testimonial-wrape" component="section">
        <Index.Grid className="container" container>
          <Index.Grid item lg={12} className="grid">
            <Index.Typography component="h4">
              Personalize your buying and selling experience with curated benefits
            </Index.Typography>
            <Index.Box className="crousel-wrape">
              <PageIndex.Swiper
                loop={true}
                modules={[PageIndex.Navigation, PageIndex.Pagination]}
                className="mySwiper"
                navigation={{
                  nextEl: ".swiper-button-nexts",
                  prevEl: ".swiper-button-prevs",
                }}
              >
                <PageIndex.SwiperSlide>
                  <Index.Box className="left-wrape">
                    <Index.Box className="left-wrape-img">
                    <img src={PageIndex.Png.user} />
                    </Index.Box>
                    <Index.Box className="content">
                      <Index.Typography component="h6">
                        Rahul Sharma
                      </Index.Typography>
                      <Index.Typography component="span">
                        COO STELMART
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="right-wrape">
                    <Index.Typography component="p">
                      Selling on STELMART will be a game-changer for any business. The platform's user-friendly interface, coupled with its extensive network of buyers, will not only simplify the selling process but also significantly expand the reach to a large number of buyers across the country. Real-time communication and transparent transactions will make the seller/buyer experience exceptionally smooth and profitable.
                    </Index.Typography>
                  </Index.Box>
                </PageIndex.SwiperSlide>
                <PageIndex.SwiperSlide>
                  <Index.Box className="left-wrape">
                    <Index.Box className="left-wrape-img">
                    <img src={PageIndex.Png.user} />
                    </Index.Box>
                    <Index.Box className="content">
                      <Index.Typography component="h6">
                        Rahul Sharma
                      </Index.Typography>
                      <Index.Typography component="span">
                        COO STELMART
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="right-wrape">
                    <Index.Typography component="p">
                      Selling on STELMART will be a game-changer for any business. The platform's user-friendly interface, coupled with its extensive network of buyers, will not only simplify the selling process but also significantly expand the reach to a large number of buyers across the country. Real-time communication and transparent transactions will make the seller/buyer experience exceptionally smooth and profitable.
                    </Index.Typography>
                  </Index.Box>
                </PageIndex.SwiperSlide>
              </PageIndex.Swiper>
              <Index.Box className="control">
                <Index.Box className="swiper-button-prevs">
                  <img src={PageIndex.Svg.testiprevarrrow} />
                </Index.Box>
                <Index.Box className="swiper-button-nexts">
                  <img src={PageIndex.Svg.testinextarrrow} />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      {/* testimonial section end */}

      {/* help section start */}
      <Index.Box className="help-wrape" component="section">
        <Index.Box className="container">
          <Index.Typography component="h5">
            How can we help you?{" "}
          </Index.Typography>
          <Index.Typography component="p">
            <Index.Link to="/coming-soon">Get in touch</Index.Link> with us or find an{" "}
            <Index.Link to="/coming-soon">office</Index.Link> closest to you.
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      {/* help section end */}
    </>
  );
}
