import React from 'react'
import Index from '../../Index'
import PageIndex from '../../PagesIndex'

export default function Footer() {
  return (
    <>
      <Index.Box className="footer" component="footer" >
        <Index.Box className="container">
          <Index.Box className='grid-row footer-row'>
            
          <Index.Box className="footer-logo-box">
              <img src={PageIndex.Svg.logo} className='footer-logo' alt='logo' />
            </Index.Box>
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                <Index.Box gridColumn={{ xs: "span 12", sm: "span 4", md: "span 4", lg: "span 4" }} className="grid-column">
                  <Index.Box className="footer-col">
                  <Index.Typography className='footer-nav-titles'>Address</Index.Typography>
                    <Index.Typography className='footer-para'>
                      Electrip Private Limited 606, Unitech Arcadia, South City-2, Sector-49, Gurgaon, Haryana-122018
                    </Index.Typography>
                    <Index.Typography className='footer-para'>
                      <b>Landline Number:</b> <Index.Link to="tel:0124-4438002">0124-4438002</Index.Link>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box gridColumn={{ xs: "span 12", sm: "span 8", md: "span 8", lg: "span 8" }} className="grid-column">
                  <Index.Box className='grid-row'>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                        <Index.Box gridColumn={{ xs: "span 12", sm: "span 4", md: "span 4", lg: "span 4" }} className="grid-column">
                          <Index.Box className="footer-inner-col">
                            <Index.Box className="footer-nav-main">
                              <Index.Typography className='footer-nav-titles'>Solutions</Index.Typography>

                              <Index.List className='footer-nav-ul'>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Extensive product search</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Strategic Financing</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Supply chain management</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Technical support</Index.Link>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box gridColumn={{ xs: "span 12", sm: "span 4", md: "span 4", lg: "span 4" }} className="grid-column">
                          <Index.Box className="footer-inner-col">
                            <Index.Box className="footer-nav-main">
                              <Index.Typography className='footer-nav-titles'>Company</Index.Typography>
                              <Index.List className='footer-nav-ul'>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>About Stelmart</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Contact Us</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Careers</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Blogs</Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>Product Catalogue</Index.Link>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box gridColumn={{ xs: "span 12", sm: "span 4", md: "span 4", lg: "span 4" }} className="grid-column">
                          <Index.Box className="footer-inner-col">
                            <Index.Box className="footer-nav-main">
                              <Index.Typography className='footer-nav-titles'>Socials</Index.Typography>
                              <Index.List className='footer-nav-ul social-wrape'>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>
                                    <img src={PageIndex.Svg.insta} />
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>
                                    <img src={PageIndex.Svg.facebook} />
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>
                                    <img src={PageIndex.Svg.linkedin} />
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className='footer-nav-li'>
                                  <Index.Link className='footer-nav-link'>
                                    <img src={PageIndex.Svg.twiter} />
                                  </Index.Link>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="container">
          <Index.Box className="footer-copy-right-box">
            <Index.Typography className='footer-copy-right-text'>
              All Copyrights Reserved | <Index.Link to="/coming-soon" title='Terms and Conditions'>Terms and Conditions</Index.Link> | <Index.Link to="/coming-soon" title='Privacy Policy'>Privacy Policy</Index.Link>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="overlay-wrape"></Index.Box>
      </Index.Box>
    </>
  )
}
