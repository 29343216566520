import Routes from './routes/Routes'
import './assets/style/style.css';

import './component/user/defaulLayout/defaultLayout.css'
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';



// import './assets/style/swiper.css';
import './assets/style/responsive.css';

function App() {
  return (
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
