import React from "react";
import Index from "../../../Index";

function Comingsoon() {
  return (    
    <Index.Box className="coming-soon-wrape">        
        <Index.Typography component="h2">Coming<span>soon</span></Index.Typography>
    </Index.Box>
  )
}

export default Comingsoon