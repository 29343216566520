
import slide3 from './images/jpg/slider3.jpg';
import slide1 from './images/jpg/slider1.jpg';
import slide2 from './images/jpg/slider2.jpg';
import joinus from './images/jpg/joinus.jpg';

const Jpg = {
    slide1,
    slide2,
    slide3,
    joinus,
}

export default Jpg;