import logo from './images/svg/brand.svg';
import facebook from './images/svg/facbook.svg';
import linkedin from './images/svg/linkedin.svg';
import twiter from './images/svg/twitter.svg';
import insta from './images/svg/insta-icon.svg';

import bluearrowbig from './images/svg/bluearrowBig.svg';
import bluearrow from './images/svg/blluearrow.svg';
import roundarrow from './images/svg/arrow_circle_right.svg';
import whitelongarrow from './images/svg/white-long-arrow.svg';
import prvwhitethik from './images/svg/prev-white-thik-arrow.svg';
import nxtwhitethik from './images/svg/next-white-thik-arrow.svg';
import prvblackarrrow from './images/svg/prev-black-arrow.svg';
import nxtblackarrrow from './images/svg/next-black-arrow.svg';
import frwdbluearrrow from './images/svg/arrow_forward_blue.svg';
import frwdorangearrrow from './images/svg/arrow_circle_right-orange.svg';
import frwmtorangearrrow from './images/svg/material-symbols_arrow-forward.svg';
import backmtorangearrrow from './images/svg/material-symbols_arrow-backward.svg';
import testiprevarrrow from './images/svg/testimonial-prev-arrow.svg';
import testinextarrrow from './images/svg/testimonial-arrow-next.svg';

import customer from './images/svg/icon-customer.svg';
import search from './images/svg/search.svg';
import bluesearch from './images/svg/blue-search.svg';
import bluesetting from './images/svg/blue-setting.svg';
import bluetick from './images/svg/blue-tick.svg';
import bluelist from './images/svg/bluelist.svg';

import appwork from './images/svg/appwork.svg';

import howto from './images/svg/how_to_reg.svg';
import exportnote from './images/svg/export_notes.svg';
import partnerexchange from './images/svg/partner_exchange.svg';
import route from './images/svg/fa-solid_route.svg';
import verifyblack from './images/svg/verified_2.svg';
import mystery from './images/svg/mystery.svg';
import speaker from './images/svg/speaker_notes.svg';
import game from './images/svg/game-icons_pay-money.svg';
import delivery from './images/svg/solar_delivery-bold.svg';
import burgermenu from './images/svg/burger-menu.svg';


const Svg = {
    howto,
    exportnote,
    partnerexchange,
    route,
    verifyblack,
    mystery,
    speaker,
    game,
    delivery,
    logo,
    facebook,
    insta,
    linkedin,
    twiter,
    bluearrowbig,
    bluearrow,
    roundarrow,
    customer,
    search,
    bluesearch,
    bluesetting,
    bluetick,
    bluelist,
    whitelongarrow,
    prvwhitethik,
    nxtwhitethik,
    prvblackarrrow,
    nxtblackarrrow,
    frwdbluearrrow,
    frwdorangearrrow,
    frwmtorangearrrow,
    backmtorangearrrow,
    appwork,
    burgermenu,
    testiprevarrrow,
    testinextarrrow,
}

export default Svg;