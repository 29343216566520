
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Jpg from "../assets/jpg";

import Header from "../component/user/defaulLayout/Header";
import Footer from "../component/user/defaulLayout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';

export default {
      Svg,
      Png,
      Jpg,
      Header,
      Footer,
      Swiper,
      SwiperSlide,
      Navigation,
      Pagination,
};