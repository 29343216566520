import React, { useState } from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex';

export default function Header() {

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    document.body.classList[isActive?"remove":"add"]('body-overflow');
  };
  return (
    <>
      <Index.Box className="header-main" component="header">
        <Index.Box className="container">
          <Index.Box className="header-row">
            <Index.Box className="header-cust-col logo-wrape">
              <Index.Link className="header-logo-box" to="/">
                <img src={Index.Svg.logo} className='header-logo' alt='logo' />
              </Index.Link>
              
              <Index.Button  className='mobile-menu-btn' onClick={handleClick}>
                  <img src={PagesIndex.Svg.burgermenu } alt='' />
                </Index.Button>
            </Index.Box>
            <Index.Box className="header-cust-col navigation">
              <Index.Box className={`header-nav-main ${isActive?"mobilemenu-active":""}`}>
                <Index.List className='header-nav-ul'>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Categories</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>About us</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Solutions</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Financial Services </Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>FAQ</Index.Link>
                  </Index.ListItem>
                </Index.List>
                <Index.List className='header-nav-ul mobile-show'>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link to="/" className='header-nav-link'>Buyer Central</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link to="/" className='header-nav-link'>Become a supplier</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link to="/" className='header-nav-link'>Get the app</Index.Link>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col right-wrape">
              <Index.Box className="header-right-main">
                <Index.Box className="header-search-box">
                  <img src={Index.Svg.search} className='header-search-icon' />
                  <Index.TextField fullWidth  id="fullWidth"  className="form-control"  placeholder="Search"/>
                </Index.Box>
                <Index.Box className="auth-wrape">
                  <img src={PagesIndex.Svg.customer} />
                  <Index.Link className='header-login-link'>
                     Login/Register</Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
