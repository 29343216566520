import React from 'react'
import { Outlet } from 'react-router-dom'
import Index from '../../../../component/Index';
import PagesIndex from '../../../../component/PagesIndex';

const Layout = () => {
  return (
    <>
      <PagesIndex.Header />
      <Outlet />
      <PagesIndex.Footer />
    </>
  )
}

export default Layout;